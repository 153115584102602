<template>
  <div class="content">
    <div class="top">
      <img
        src="../assets/vpn/banner.jpg"
        alt=""
        width="100%"
        v-if="areaW > 768"
      />
      <div class="logo" v-else>
        <img src="../assets/vpn/logo.png" alt="" width="40" />
        FlashGo
      </div>
      <div class="check">
        <img
          :src="curLan ? d_zh : a_zh"
          alt=""
          @click="curLan = false"
          style="margin-right: 10px"
        />
        <img :src="curLan ? a_en : d_en" alt="" @click="curLan = true" />
      </div>
      <div class="login" @click="loginOrSign" v-if="isIOS">立即注册</div>
    </div>
    <img
      src="../assets/vpn/index.jpg"
      alt=""
      v-if="curLan"
      width="100%"
      style="display: block"
      :style="{
        paddingTop: areaW < 768 ? '60px' : '0px',
      }"
    />
    <img
      src="../assets/vpn/zhindex.png"
      alt=""
      width="100%"
      style="display: block"
      :style="{
        paddingTop: areaW < 768 ? '60px' : '0px',
      }"
      v-else
    />
    <div class="background">
      <div
        class="desc"
        v-for="item in descList"
        :key="item.desc1"
        v-show="!curLan"
      >
        <div class="muzhi">
          <div class="mzimg">
            <img src="../assets/vpn/tj_sel.png" alt="" />
          </div>
          <span>{{ item.desc1.split("|")[0] }}</span>
        </div>
        <div class="content">{{ item.desc1.split("|")[1] }}</div>
      </div>
      <div
        class="desc2"
        v-for="(item, index) in descList"
        :key="index"
        v-show="curLan"
      >
        <!-- <div class="muzhi" :class="'muzhi' + `${index}`">
          <div class="mzimg">
            <img src="../assets/vpn/tj_sel.png" alt="" class="mzimg" />
          </div>
          <span>{{ item.desc2.split("|")[0] }}</span>
        </div> -->
        <div class="content">
          {{ item.desc2.split("|")[1] }}

          <img src="../assets/vpn/tj_sel.png" alt="" class="mzimg" />
        </div>
      </div>
      <img
        src="../assets/vpn/apps.png"
        alt=""
        style="width: 600px; display: block; margin: 30px auto"
        class="apps-banner"
      />
      <div
        class="vip"
        :style="{
          marginBottom: !curLan && areaW < 768 ? '100px' : '0px',
        }"
      >
        {{ curLan ? "MONTHLY  MEMBERSHIP" : "月度会员" }}
      </div>
      <div
        class="price-box"
        v-for="item in priceList"
        :key="item.id"
        :style="{
          marginTop:
            !curLan && areaW < 768
              ? '60px'
              : curLan && areaW < 768
              ? '100px'
              : '',
          height:
            !curLan && areaW < 768
              ? '120px !important'
              : curLan && areaW < 768
              ? '180px !important'
              : '',
        }"
      >
        <div class="video">
          <span
            ><img
              src="../assets/vpn/taocan2.png"
              alt=""
              height="18px"
              style="margin-top: 13px"
              class="taocan" /></span
          ><span style="color: #190c67">{{ curLan ? item.tc1 : item.tc }}</span>
        </div>
        <div
          class="pribox"
          :style="{
            height: !curLan && areaW < 768 ? '120px' : '',
          }"
        >
          <div class="item" v-if="!curLan">
            <div class="shihe">
              <div class="user">
                <div class="userimg" v-if="areaW < 768">
                  <img :src="item.userImg" alt="" height="20px" />
                </div>
                {{ item.for }}
              </div>
              <div class="user">
                <div class="user1">
                  <div v-for="obj in item.forobj" :key="obj" class="uer-tit">
                    {{ obj }}
                  </div>
                </div>
              </div>
              <div class="user" @click="setPay(item.pri)">
                <div
                  class="btn"
                  :style="{
                    marginTop: '40px',
                  }"
                  :class="[item.pri == '199.9' ? 'pr199' : '']"
                >
                  <div
                    class="jiage"
                    :class="[item.pri == '199.9' ? 'pri199' : '']"
                  >
                    <span class="myuan">$</span>
                    {{ item.pri }}
                  </div>
                  <span class="buy" v-if="areaW > 768">购买</span>
                  <img
                    src="../assets/vpn/buy.png"
                    alt=""
                    v-else
                    height="25px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="item item2" v-if="curLan">
            <div class="shihe1">
              <div class="user1">
                <div class="line" v-for="obj in item.for1" :key="obj">
                  {{ obj }}
                </div>
              </div>
              <div class="desc1">
                <div class="line" v-for="obj1 in item.forobj1" :key="obj1">
                  {{ obj1 }}
                </div>
              </div>
            </div>
            <div class="btn1" @click="setPay(item.pri)">
              <div class="jiage">
                <span class="myuan">$</span>
                {{ item.pri }}
              </div>
              <span>Buy</span>
            </div>
          </div>
        </div>
      </div>
      <div class="apps">
        <div class="appl">
          <span></span>{{ curLan ? "APPLY" : "适用于" }}<span></span>
        </div>
        <div class="appdown">
          <div class="down" @click="downloadApps('win')">
            <img src="../assets/vpn/win.png" alt="" />
            Windows
          </div>
          <div class="down" @click="downloadApps('mac')">
            <img src="../assets/vpn/mac.png" alt="" />
            macOS
          </div>
          <div class="down" @click="downloadApps('android')">
            <img src="../assets/vpn/an.png" alt="" />
            Android
          </div>
          <div class="down" @click="downloadApps('ios')">
            <img src="../assets/vpn/ios.png" alt="" />
            iOS
          </div>
        </div>
      </div>
      <div class="fotter" v-if="!curLan">
        下载闪电狗VPN移动应用程序,iOS和Android平台均适用
      </div>
      <div
        class="fotter"
        :style="{
          'line-height': curLan ? '50px' : '',
        }"
        v-else
      >
        Download flash go VPN mobile app <br />
        for both iOS and Android platforms
      </div>
    </div>

    <!-- 添加自定义提示框 -->
    <div class="custom-alert" v-if="showAlert">
      <div class="alert-content">
        <div class="alert-title">
          <img src="../assets/vpn/logo.png" alt="" width="30" />
          <span>温馨提示</span>
        </div>
        <div class="alert-message">
          {{ alertMessage }}
        </div>
        <div class="alert-btn" @click="closeAlert">
          确定
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BatIndex",
  mounted() {
    this.checkPlatform();
    // console.log();
    // this.getBackSize();
    // window.addEventListener("resize", () => {
    //   this.getBackSize();
    // });
  },
  methods: {
    loginOrSign() {
      window.open(`https://hk.flashgo.net/register?platform=${this.isIOS ? 'ios' : 'android'}`, "_blank");
    },
    setPay(v) {
      console.log(v, "price");
      this.alertMessage = '请安装客户端后在应用内购买套餐';
      this.showAlert = true;
    },
    downloadApps(v) {
      switch (v) {
        case "ios":
          window.open("https://apps.apple.com/us/app/hiddify-proxy-vpn/id6596777532", "_blank");
          break;
        case "mac":
          window.open("https://pkgs-hk.flashgo.net/client/FlashGo_latest.dmg", "_blank");
          break;
        case "win":
          window.open("https://pkgs-hk.flashgo.net/client/FlashGo_latest.exe", "_blank");
          break;
        case "android":
          window.open("https://pkgs-hk.flashgo.net/client/FlashGo_latest.apk", "_blank");
          break;
        default:
          break;
      }
    },
    getBackSize() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.buy) {
            this.buyHeight = this.$refs.buy.clientHeight;
            console.log("买区的高度:", this.buyHeight);
          } else {
            console.error("买区的 ref 未正确引用");
          }
        }, 500);
      });
      // let img = new Image();
      // img.src = this.back;
      // img.onload = () => {
      //   console.log(img.height, img.width);
      // };
    },
    checkPlatform() {
      const u = navigator.userAgent;
      const isiOS = /iPad|iPhone|iPod/.test(u) && !window.MSStream;
      this.isIOS = isiOS;
    },
    closeAlert() {
      this.showAlert = false;
    }
  },
  data() {
    return {
      buyHeight: 0,
      back: require("../assets/vpn/back.png"),
      a_zh: require("../assets/vpn/a_zh.png"),
      a_en: require("../assets/vpn/a_en.png"),
      d_zh: require("../assets/vpn/d_zh.png"),
      areaW: window.innerWidth,
      d_en: require("../assets/vpn/d_en.png"),
      curLan: false,
      descList: [
        {
          desc1: "自主研发协议|直播不掉线",
          desc2: "INDEPENDENT RESEARCH  AGREEMENT|SMOOTHY LIVE STREAMING",
        },
        {
          desc1: "稳定畅联|永不限速",
          desc2: "STABLE OPEN LINK|NO LIMITATION ON SPEED",
        },
        {
          desc1: "多个设备|一键链接",
          desc2: "MULTIPLE DEVICES|ONE-CLICK CONNECTION",
        },
        {
          desc1: "海外技术|智能加速",
          desc2: "OVERSEAS TECHNOLOGY|INTELLIGENT ACCELERATION",
        },
      ],
      priceList: [
        {
          id: 0,
          pri: 2.9,
          tc: "入门套餐",
          tc1: "Lite package",
          userImg: require("../assets/vpn/user.png"),
          for: window.innerWidth < 768 ? "个人用户" : "适用个人用户",
          for1: ["Individual"],
          forobj1: [
            "Supports two devices",
            "200GB high speed traffic",
            "For Scientific Resrarch/ Entertainment",
          ],
          forobj: ["支持2台设备", "200GB高速流量", "科学上网好助手"],
        },
        {
          id: 1,
          userImg: require("../assets/vpn/user.png"),
          pri: 9.9,
          for: window.innerWidth < 768 ? "个人用户" : "适用个人用户",
          forobj: ["支持5台设备", "500GB高速流量", "视频娱乐无卡顿"],
          for1: ["Enterprise", "Basic"],
          forobj1: [
            "Supports five devices",
            "500GB high speed traffic",
            "For Social media/Entertainment",
          ],
          tc1: "Standard package",
          tc: "基础套餐",
        },
        {
          id: 2,
          userImg: require("../assets/vpn/qiye.png"),
          pri: 29.9,
          for: window.innerWidth < 768 ? "企业出海" : "企业出海专线",
          tc1: "Pro package",
          forobj: ["支持10台设备", "1000GB高速流量", "纯净家宅IP"],
          for1: ["Enterprise", "Business Pro"],
          forobj1: [
            "Supports ten  devices",
            "1000GB high speed traffic",
            "Pure home IP/Tik Tok/ChatGPT",
          ],
          tc: "企业套餐",
        },
        {
          id: 3,
          userImg: require("../assets/vpn/qiye.png"),
          pri: 99,
          forobj: ["直播专线不延迟", "支持更多设备", "5000GB高速流量"],
          for: window.innerWidth < 768 ? "企业出海" : "企业出海专线",
          tc1: "Premium package",
          tc: "高级套餐",
          for1: ["Enterprise", "Premium"],
          forobj1: [
            "Specialized for live streaming",
            "Supports for more devices",
            "5000GB high speed traffic",
          ],
        },
        {
          id: 4,
          userImg: require("../assets/vpn/dingzhi.png"),
          pri: 199.9,
          for: window.innerWidth < 768 ? "定制用户" : "适合定制用户",
          tc1: "Costomized service",
          forobj: [
            "不限流量畅游无阻",
            "更大带宽",
            "独享纯净IP",
            "量身定制独一无二",
          ],
          tc: "定制套餐",
          for1: ["High-end demand", "Customers"],
          forobj1: [
            "Unlimited traffic",
            "Greater bandwidth",
            "Exclusive pure IP",
            "Exclusive customization",
          ],
        },
      ],
      isIOS: false,
      showAlert: false,
      alertMessage: '',
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  overflow: hidden;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: "Source Han Sans", sans-serif;
  .background {
    background: url("../assets/vpn/back.png") no-repeat center center;
    background-size: 100% 100%;
    height: auto;
    padding-top: 20px;
    @keyframes blink-animation {
      0% {
        opacity: 1; /* 完全可见 */
      }
      50% {
        opacity: 0; /* 完全不可见 */
      }
      100% {
        opacity: 1; /* 再次可见 */
      }
    }
    @keyframes breathing {
      0%,
      100% {
        transform: scale(1); /* 初始和结束时元素大小为原始大小 */
        opacity: 1; /* 元素完全可见 */
      }
      50% {
        transform: scale(1.2); /* 在中间时放大20% */
        opacity: 0.3; /* 透明度降低为0.3 */
      }
    }
    .desc {
      background: linear-gradient(to right, #34c3ff, #1c95ef, #0d5edb);
      width: 400px;
      height: 60px;
      margin: 0 auto;
      border-radius: 12px;
      margin-bottom: 20px;
      display: flex;
      font-size: 16px;
      align-items: center;
      justify-content: space-around;
      box-shadow: 4px 6px 35px rgba(255, 255, 255, 0.4);

      .muzhi {
        display: flex;
        width: 50%;
        align-items: center;
        border-radius: 4px;
        background: #fff;
        margin-left: 40px;
        font-weight: 900;
        position: relative;
        text-align: right;
        .mzimg {
          margin-right: 5px;

          position: absolute;
          top: -5px;
          left: 5px;
          img {
            animation: breathing 2s infinite;
            height: 20px;
          }
        }
        span {
          width: 100%;
          margin-right: 5px;
          font-size: 20px;
          text-align: center;
          padding-left: 30px;
        }
        color: #325182;
      }
      .content {
        width: 50%;
        color: #fff;
        display: flex;
        margin-right: 10px;
        align-items: center;
        font-size: 26px;
        margin-left: 10px;
        font-weight: 900;
      }
    }
    .desc2 {
      background: linear-gradient(to right, #34c3ff, #1c95ef, #0d5edb);
      width: 500px;
      height: 80px;
      margin: 0 auto;
      border-radius: 12px;
      margin-bottom: 40px;
      font-size: 16px;
      position: relative;
      box-shadow: 4px 6px 35px rgba(255, 255, 255, 0.4);
      .muzhi {
        top: 5px;
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
        display: flex;

        width: 90%;
        align-items: center;
        border-radius: 4px;
        background: #fff;
        font-weight: 900;
        line-height: 30px;
        position: relative;
        text-align: right;
        .mzimg {
          animation: breathing 2s infinite;
          margin-right: 5px;
          position: absolute;
          top: -5px;
          left: 5px;
          img {
            height: 20px;
          }
        }
        span {
          width: 100%;
          margin-right: 5px;
          font-size: 20px;
          text-align: center;
          padding-left: 30px;
        }
        color: #325182;
      }

      .content {
        .mzimg {
          margin-right: 5px;

          position: absolute;
          top: 15px;
          left: 10px;
          animation: breathing 2s infinite;
          height: 20px;
        }
        width: 90%;
        position: absolute;
        color: #fff;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        text-align: center;
        line-height: 40px;
        align-items: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        font-weight: 900;
      }
    }
    @keyframes filter {
      0% {
        filter: brightness(1);
      }
      35% {
        filter: brightness(0.95);
      }
      55% {
        filter: brightness(0.9);
      }
      75% {
        filter: brightness(0.85);
      }
      100% {
        filter: brightness(0.8);
      }
    }
    .apps-banner {
      animation: filter 6s infinite ease-in-out;
    }
    @keyframes shadow {
      0%,
      21%,
      31%,
      51%,
      66%,
      70%,
      80%,
      90% {
        color: #fff;
      }
      5%,
      15%,
      35%,
      45%,
      55%,
      65%,
      75%,
      100% {
        color: #fbbc05;
        text-shadow: 0 0 10px #34c3ff, 0 0 20px #34c3ff, 0 0 30px, #34c3ff,
          0 0 40px #34c3ff;
      }
    }
    .vip {
      -webkit-box-reflect: below 1px
        linear-gradient(transparent, rgba(0, 0, 0, 0.4));
      animation: shadow 5s linear infinite;
      background: linear-gradient(to right, #34c3ff, #1c95ef, #0d5edb);
      width: 270px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      height: 40px;
      margin: 0 auto;
      border-radius: 10px;
    }
    .fotter {
      width: 100%;
      font-size: 26px;
      text-align: center;
      color: #fff;
      line-height: 100px;
      font-weight: normal;
    }
    .apps {
      margin-top: 40px;
      width: 100%;
      height: 200px;
      background: linear-gradient(to right, #4754d6, #4855d7, #5ae8ec);
      position: relative;
      .appdown {
        display: flex;
        font-weight: 600;
        color: #fff;
        width: 600px;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: 80px;
        .down {
          cursor: pointer;
          width: 100%;
          text-align: center;
          img {
            height: 50px;
            display: block;
            &:nth-child(3) {
              width: 50px;
            }
            margin: 0 auto;
            margin-bottom: 20px;
          }
        }
      }
      .appl {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-items: center;
        span {
          width: 100px;
          display: block;
          height: 2px;
          background: #fff;
          margin-right: 20px;
          &:nth-child(2) {
            margin-right: 0;
            margin-left: 20px;
          }
        }
        color: #fff;
        font-size: 26px;
        margin: 0 auto;
      }
    }
    .price-box {
      position: relative;
      width: 600px;
      margin: 0 auto;
      margin-top: 100px;
      &:nth-child(1) {
        margin-top: 50px !important;
      }
      .video {
        background: #fff;
        padding: 0 10px;
        height: 40px;
        position: absolute;
        top: -40px;
        left: 24px;
        border-top-right-radius: 16px;
        display: flex;
        align-items: center;
        border-top-left-radius: 16px;
        border-bottom-right-radius: 16px;
        color: #0e005f;
        box-shadow: 2px -4px 10px #eee;
        font-size: 20px;
        span {
          line-height: 40px;
          &:nth-child(1) {
            margin-right: 5px;
          }
        }
      }

      .pribox {
        background: #fff;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 24px;
        box-shadow: 2px -4px 10px #eee;
        .item {
          width: 80%;
          height: 80%;
          border-radius: 20px;
          background: linear-gradient(to right, #f8f8fa, #f2eff5);
          .shihe {
            width: 100%;
            height: 100%;
            display: flex;
            padding: 0 20px;
            .user {
              position: relative;
              .uer-tit {
                line-height: 30px;
              }
              .user1 {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
              }
              &:nth-child(1) {
                font-weight: 900;
                font-size: 20px;
              }
              &:nth-child(2) {
                display: block;
              }
              &:nth-child(3) {
                display: block;
                cursor: pointer;
                .buy {
                  font-size: 14px;
                  position: absolute;
                  right: 5px;
                  bottom: 4px;
                  z-index: 9;
                }
                .btn {
                  box-shadow: 2px 2px 8px #373195;
                  &::after {
                    content: "";
                    width: 10px;
                    height: 10px;
                    background: #373195;
                    position: absolute;
                    right: -5px;
                    transform: rotate(45deg);
                    top: 43%;
                  }
                  .jiage {
                    position: relative;
                    font-size: 26px;
                    .myuan {
                      position: absolute;
                      left: -15px;
                      margin: 0;
                      top: 0;
                      font-size: 26px;
                      font-weight: 900;
                    }
                  }
                  width: 140px;
                  height: 60px;
                  display: flex;
                  align-items: center;
                  justify-items: center;
                  margin-top: 50px;
                  margin-left: -20px;
                  background: linear-gradient(
                    to right,
                    #4f0d95,
                    #452296,
                    #323594
                  );
                  color: #fff;
                  border-radius: 10px;
                  font-size: 26px;
                  position: relative;
                  justify-content: center;
                  font-weight: 900;
                  &:nth-child(1) {
                    font-size: 14px;
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    font-weight: normal;
                  }
                  span {
                    &:nth-child(1) {
                      font-size: 16px;
                      font-weight: normal;
                      margin-top: 5px;
                      margin-left: 5px;
                    }
                  }
                }
              }
              width: 100%;
              color: #000;
              display: flex;
              align-items: center;
              justify-items: center;
            }
          }
        }
        .item2 {
          display: flex;
          height: 50%;
          position: relative;
          margin-top: -50px;
          .btn1 {
            filter: drop-shadow(2px 4px 4px #452296);
            .jiage {
              position: relative;
              .myuan {
                position: absolute;
                font-size: 26px;
                left: -18px;
                top: -2px;
                left: -20px;
                top: -5px;
                font-weight: 900;
              }
            }
            &::after {
              content: "";
              width: 10px;
              height: 10px;
              background: #373195;
              position: absolute;
              right: -5px;
              transform: rotate(45deg);
              top: 27px;
            }
            width: 150px;
            cursor: pointer;
            height: 60px;
            display: flex;
            align-items: center;
            justify-items: center;
            background: linear-gradient(to right, #4f0d95, #452296, #323594);
            color: #fff;
            border-radius: 10px;
            font-size: 26px;
            position: relative;
            justify-content: center;
            font-weight: 900;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -70px;
            &:nth-child(1) {
              font-size: 14px;
              position: absolute;
              top: 10px;
              left: 10px;
              font-weight: normal;
            }
            span {
              &:nth-child(1) {
                font-size: 21px;
                font-weight: normal;
                margin-top: 5px;
                margin-left: 5px;
              }
              &:nth-child(2) {
                font-size: 16px;
                font-weight: normal;
                position: absolute;
                right: 10px;
                z-index: 999;
                bottom: 10px;
              }
            }
          }
          .shihe1 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            line-height: 40px;
            .user1 {
              width: 30%;
              font-size: 15px;
              font-weight: 900;
              margin-left: 20px;
            }
            .desc1 {
              width: 60%;
              font-size: 14px;
              line-height: 26px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .content {
    .login {
      color: #fff !important;
      font-size: 12px !important;
    }
    width: 100%;
    font-size: 12px;
    .check {
      right: 80px !important;
      img {
        height: 16px !important;
      }
    }
    .desc {
      width: 80% !important;
      .content {
        font-size: 22px !important;
      }
      .muzhi {
        margin-left: 30px !important;
        .mzimg {
          top: -9px !important;
          left: 4px !important;
        }
        span {
          font-size: 17px !important;
          margin-right: 0 !important;
          padding: 5px !important;
        }
      }
    }
    .desc2 {
      width: 80% !important;
      height: 70px !important;

      .content {
        font-size: 11px !important;
      }
      .muzhi0 {
        line-height: 20px !important;
      }
      .muzhi {
        span {
          font-size: 13px !important;
        }
      }
    }
    .apps-banner {
      width: 80% !important;
    }
    .vip {
      width: 50% !important;
      font-size: 14px !important;
    }
    .fotter {
      font-size: 14px !important;
      line-height: 30px !important;
    }
    .apps {
      .appdown {
        width: 80% !important;
      }
      .appl {
        width: 280px !important;
        font-size: 26px !important;
        span {
          width: 80px !important;
        }
      }
    }
    .price-box {
      width: 80% !important;
      height: 180px !important;
      .item {
        width: 90% !important;
        //中文
        .shihe {
          line-height: 20px;
          position: relative;
          padding: 0 5px !important;
          .user {
            margin-left: 0 !important;
            position: absolute;
            width: 100% !important;
            line-height: 40px !important;
            position: relative;
            &:nth-child(1) {
              font-size: 14px !important;
              font-weight: 900;
              width: 60% !important;
              display: block !important;
              text-align: center !important;
              .userimg {
                margin-top: 20px;
                img {
                  display: block !important;
                  margin: 0 auto !important;
                  height: 30px;
                }
              }
            }
            &:nth-child(2) {
              margin-left: 10px !important;
            }
            &:nth-child(3) {
              position: relative;
              @keyframes identifier {
                0% {
                  opacity: 0.6;
                  transform: scale(0.5);
                }
                50% {
                  opacity: 0.8;
                  transform: scale(0.8);
                }
                100% {
                  opacity: 0.8;
                  transform: scale(1);
                }
              }
              img {
                position: absolute !important;
                right: -4px;
                top: 0;
                animation: identifier 3s infinite ease-in-out;
                z-index: 99;
              }
              margin-left: -10px !important;
              .btn {
                width: 85px !important;
                background: linear-gradient(
                  to right,
                  #4f0d95,
                  #452296,
                  #323594
                );
                height: 40px !important;
                margin-top: 20px !important;
                font-size: 18px !important;
                margin-left: -10px !important;
                padding-right: 2px;
                margin: 0 !important;
                position: absolute !important;
                top: 50% !important;
                left: 45% !important;
                transform: translate(-50%, -50%) !important;
                .buy {
                  font-size: 11px !important;
                  position: absolute;
                  right: 4px;
                  bottom: -8px;
                  z-index: 9;
                }
                .jiage {
                  font-size: 21px !important;
                  margin-left: 13px !important;
                }
                // .pri199 {
                //   margin-left: 18px !important;
                // }
                .myuan {
                  top: 0 !important;
                  left: -14px !important;
                  font-size: 21px !important;
                  margin: 0 !important;
                  font-weight: 900 !important;
                }
                &::after {
                  top: 40% !important;
                }
              }
            }
            .user1 {
              .uer-tit {
                line-height: 20px !important;
              }
            }

            .pr199 {
              left: 15px !important;
            }
          }
        }
        .shihe1 {
          line-height: 20px;
          position: relative;
          .user1 {
            margin-left: 10px !important;
            position: absolute;
            font-size: 12px !important;
            width: 100% !important;
            line-height: 40px !important;
          }
        }
        .btn1 {
          span {
            font-size: 20px;
            margin-top: 5px;
            margin-left: 5px;

            right: 4px !important;
          }
          .jiage {
            text-indent: 0.5em;
            .myuan {
              left: -20px !important;
              font-weight: 900 !important;
              margin-top: 4px !important;
              margin-left: 4px !important;
              margin-right: 5px;
            }
          }
        }
        // !important
        .desc1 {
          width: 85% !important;
          font-size: 12px !important;
          margin-right: 10px !important;
          text-align: end;
        }
      }
    }
  }
}
.top {
  position: fixed;
  width: 100%;
  background: linear-gradient(to right, #070b6c, #221979, #600a93);
  z-index: 9999;
  height: 60px;
  .logo {
    display: flex;
    align-items: center;
    height: 60px;

    justify-items: center;
    img {
      margin: 0 10px;
    }
    color: #fff;
    font-weight: 900;
    font-size: 16px;
  }
  .login {
    position: absolute;
    max-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: scale(0.5);
    right: 20px;
    cursor: pointer;
    color: #000;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .check {
    position: absolute;
    max-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: scale(0.5);
    right: 140px;
    transform: translateY(-50%);
    cursor: pointer;
    img {
      height: 25px;
    }
  }
}
.buy {
  box-sizing: border-box;
  position: relative;
}
.button-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .button-box1 {
    width: 100%;
    margin: 25px auto;
    .btn-img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.button-box1 img:last-child {
  margin-bottom: 0;
}
.content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.custom-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  .alert-content {
    background: #fff;
    width: 80%;
    max-width: 320px;
    border-radius: 12px;
    overflow: hidden;
    animation: alertSlideIn 0.3s ease;

    .alert-title {
      background: linear-gradient(to right, #070b6c, #221979, #600a93);
      color: #fff;
      padding: 15px;
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .alert-message {
      padding: 20px;
      text-align: center;
      font-size: 16px;
      color: #333;
      min-height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .alert-btn {
      border-top: 1px solid #eee;
      padding: 12px;
      text-align: center;
      color: #fff;
      background: linear-gradient(to right, #4f0d95, #452296, #323594);
      cursor: pointer;
      font-weight: 500;

      &:active {
        opacity: 0.8;
      }
    }
  }
}

@keyframes alertSlideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

// 移动端适配
@media (max-width: 768px) {
  .custom-alert {
    .alert-content {
      width: 85%;

      .alert-title {
        padding: 12px;
        font-size: 15px;
      }

      .alert-message {
        padding: 15px;
        font-size: 14px;
        min-height: 50px;
      }

      .alert-btn {
        padding: 10px;
        font-size: 14px;
      }
    }
  }
}
</style>
