<template>
  <div id="app">
    <Index />
  </div>
</template>

<script>
import Index from "./components/Index.vue";

export default {
  name: "App",
  components: {
    Index,
  },
};
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
</style>
